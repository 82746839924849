export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Carritos abandonados',
    route: 'sessionsView',
    icon: 'PackageIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Ordenes',
    route: 'ordersView',
    icon: 'ListIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Clientes/Vendedores',
    route: 'customersView',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Productos',
    route: 'productsView',
    icon: 'PackageIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Catálogos',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Categorías',
        route: 'categoriesView',
        icon: 'GitBranchIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Marcas',
        route: 'brandsView',
        icon: 'BookIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Estados de producto',
        route: 'productStatusesView',
        icon: 'SlidersIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Categorías de Preguntas Frecuentes',
        route: 'faqCategoriesView',
        icon: 'ColumnsIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Preguntas Frecuentes',
        route: 'frequentQuestionsView',
        icon: 'HelpCircleIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Grupo de etiquetas',
        route: 'tagGroupsView',
        icon: 'HashIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Etiquetas',
        route: 'tagsView',
        icon: 'TagIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Campañas',
        route: 'campaignsView',
        icon: 'BookmarkIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Showrooms',
        route: 'showroomsView',
        icon: 'LayoutIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Comunidad',
        route: 'communityMultimediaView',
        icon: 'Share2Icon',
        action: 'read',
        resource: 'Auth',
      },
    ],
  },
  {
    title: 'Blog',
    icon: 'FolderPlusIcon',
    children: [
      {
        title: 'Entradas',
        route: 'boxesView',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Categorías',
        route: 'blogCategoriesView',
        icon: 'FolderPlusIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Etiquetas',
        route: 'blogTagsView',
        icon: 'HashIcon',
        action: 'read',
        resource: 'Auth',
      },
    ],
  },
  {
    title: 'Sistema',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Cajas',
        route: 'boxesView',
        icon: 'BoxIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Cupones',
        route: 'couponsView',
        icon: 'PercentIcon',
        action: 'read',
        resource: 'Auth',
      },

      {
        title: 'Comisiones',
        route: 'commissionsView',
        icon: 'DollarSignIcon',
        action: 'read',
        resource: 'Auth',
      },

      {
        title: 'Usuarios',
        route: 'usersView',
        icon: 'UserIcon',
        action: 'read',
        resource: 'Auth',
      },

      {
        title: 'Menú',
        route: 'menuConfigView',
        icon: 'MenuIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Banners',
        route: 'bannersView',
        icon: 'ListIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Configuración',
        route: 'configurationsView',
        icon: 'ToggleLeftIcon',
        action: 'read',
        resource: 'Auth',
      },
      {
        title: 'Slider Home',
        route: 'sellerMultimediaView',
        icon: 'Share2Icon',
        action: 'read',
        resource: 'Auth',
      },
    ],
  },

]
